var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pr-4" }, [
    _c(
      "div",
      { staticClass: "m-1 mr-4 px-0" },
      [
        _c("breadcrumb", {
          attrs: {
            title: "Consulta integrações",
            actualPage: "Consulta integrações",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "pt-10 filtro" }, [
            _c(
              "div",
              { staticClass: "item data" },
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "dd/MM/yyyy",
                    "value-format": "yyyy/MM/dd",
                    type: "daterange",
                    align: "right",
                    "unlink-panels": "",
                    "start-placeholder": "Data Inicial",
                    "end-placeholder": "Data Final",
                    "picker-options": _vm.pickerOptions,
                  },
                  model: {
                    value: _vm.paramModalDate,
                    callback: function ($$v) {
                      _vm.paramModalDate = $$v
                    },
                    expression: "paramModalDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item tipo" },
              [
                _c("el-input", {
                  staticClass: "w-full",
                  attrs: { placeholder: "Identificador (Opcional)" },
                  model: {
                    value: _vm.Identifier,
                    callback: function ($$v) {
                      _vm.Identifier = $$v
                    },
                    expression: "Identifier",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item tipo" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Escolha o tipo", clearable: "" },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: "PROPOSAL",
                      attrs: { label: "Proposta", value: "PROPOSAL" },
                    }),
                    _c("el-option", {
                      key: "POLICYHOLDER",
                      attrs: { label: "Tomador", value: "POLICYHOLDER" },
                    }),
                    _c("el-option", {
                      key: "BROKER",
                      attrs: { label: "Corretor", value: "BROKER" },
                    }),
                    _c("el-option", {
                      key: "POLICY",
                      attrs: { label: "Apólice", value: "POLICY" },
                    }),
                    _c("el-option", {
                      key: "ENDORSEMENT",
                      attrs: { label: "Endosso", value: "ENDORSEMENT" },
                    }),
                    _c("el-option", {
                      key: "CALLBACK_PROPOSAL",
                      attrs: {
                        label: "Callback de Proposta",
                        value: "CALLBACK_PROPOSAL",
                      },
                    }),
                    _c("el-option", {
                      key: "CALLBACK_BROKER",
                      attrs: {
                        label: "Callback de  Corretor",
                        value: "CALLBACK_BROKER",
                      },
                    }),
                    _c("el-option", {
                      key: "CALLBACK_POLICYHOLDER",
                      attrs: {
                        label: "Callback de Tomador",
                        value: "CALLBACK_POLICYHOLDER",
                      },
                    }),
                    _c("el-option", {
                      key: "CALLBACK_ERROR",
                      attrs: { label: "Callback de Erro", value: "ERROR" },
                    }),
                    _c("el-option", {
                      key: "CALLBACK_ENDORSEMENT",
                      attrs: {
                        label: "Callback de Endosso",
                        value: "CALLBACK_ENDORSEMENT",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "pt-10 botoes text-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", round: "", type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.limpar()
                    },
                  },
                },
                [_vm._v("Limpar")]
              ),
              _vm.paramModalDate != false && _vm.type.length > 0
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "success", round: "" },
                      on: {
                        click: function ($event) {
                          return _vm.buscar()
                        },
                      },
                    },
                    [_vm._v("Buscar\n        ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        size: "mini",
                        type: "success",
                        round: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.errorInput()
                        },
                      },
                    },
                    [_vm._v("Buscar")]
                  ),
            ],
            1
          ),
          _c("div", { staticClass: "pt-10" }, [
            _vm.NumberofRows > 0
              ? _c("h2", [_vm._v(_vm._s(_vm.NumberofRows) + " Resultados")])
              : _vm._e(),
            _c("br"),
            _c("hr"),
          ]),
        ]),
        _vm.listaIntegracoes
          ? _c(
              "div",
              { staticClass: "pt-10" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listaIntegracoes,
                      "row-class-name": _vm.tableRowClassName,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "Identifier",
                        label: "Identificação da integração",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "Tipo da integração" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType === "PROPOSAL"
                                        ? "Proposta"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "POLICYHOLDER"
                                        ? "Tomador"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType === "BROKER"
                                        ? "Corretor"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "ENDORSEMENT"
                                        ? "ENDOSSO"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType === "POLICY"
                                        ? "APÓLICE"
                                        : ""
                                    ) +
                                    "\n\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "CALLBACK_PROPOSAL"
                                        ? "Callback de Proposta"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "CALLBACK_BROKER"
                                        ? "Callback de  Corretor"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "CALLBACK_POLICYHOLDER"
                                        ? "Callback de Tomador"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "CALLBACK_ERROR"
                                        ? "Callback de Erro"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "CALBACK_ENDORSEMENT"
                                        ? "Callback de Endosso"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType === "ERROR"
                                        ? "Erro"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      scope.row.IntegrationType ===
                                        "CALLBACK_ENDORSEMENT"
                                        ? "Callback Endosso"
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4055613253
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "Data da integração" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        new Date(scope.row.CreateDate),
                                        "DD/MM/YYYY"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1023067873
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                (scope.row || {}).Archives
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled:
                                            (scope.row || {}).Archives <= 0,
                                          size: "mini",
                                          round: "",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalDocumentos(scope)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "onpoint-files icon-font",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      round: "",
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exibirDetalhes(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("Visualizar JSON")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "success",
                                      round: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Reenviar(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("Reenviar")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2862443138
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "pt-10" },
          [
            _vm.numberOfPages > 1
              ? _c("vs-pagination", {
                  attrs: { total: _vm.numberOfPages },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "vs-popup",
          {
            attrs: {
              active: _vm.detalhesModal,
              title: "Detalhes da Integração",
            },
            on: {
              "update:active": function ($event) {
                _vm.detalhesModal = $event
              },
            },
          },
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "card" },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _vm.Request
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "Request", name: "first" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", round: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyToClipboard(
                                            _vm.Request
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("\n                  Copiar JSON")]
                                  ),
                                ],
                                1
                              ),
                              _c("tree-view", {
                                attrs: {
                                  id: "element-to-print",
                                  data: _vm.Request,
                                  options: {
                                    maxDepth: 5,
                                    rootObjectKey: "JSON",
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.Response
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "Response", name: "second" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", round: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyToClipboard(
                                            _vm.Response
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("\n                  Copiar JSON")]
                                  ),
                                ],
                                1
                              ),
                              _c("tree-view", {
                                attrs: {
                                  id: "element-to-print",
                                  data: _vm.Response,
                                  options: {
                                    maxDepth: 5,
                                    rootObjectKey: "JSON",
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.Exception
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "Exception", name: "terceiro" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", round: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyToClipboard(
                                            _vm.Exception
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("\n                  Copiar JSON")]
                                  ),
                                ],
                                1
                              ),
                              _c("tree-view", {
                                attrs: {
                                  id: "element-to-print",
                                  data: _vm.Exception,
                                  options: {
                                    maxDepth: 5,
                                    rootObjectKey: "JSON",
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "vs-popup",
          {
            attrs: {
              active: _vm.modalDocumento,
              title: "Documentos da Integração",
            },
            on: {
              "update:active": function ($event) {
                _vm.modalDocumento = $event
              },
            },
          },
          [
            _c(
              "vs-table",
              {
                attrs: {
                  data: _vm.documentos,
                  hoverFlat: true,
                  noDataText: "Nenhuma registro encontrado",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return _vm._l(data, function (item, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr, attrs: { data: item } },
                          [
                            _c(
                              "vs-td",
                              {
                                staticClass: "text-left",
                                attrs: { data: data[indextr].Id },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.Id || "-") +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c(
                              "vs-td",
                              {
                                staticClass: "text-left",
                                attrs: { data: item.Filename },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.Filename) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c(
                              "vs-td",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      round: "",
                                      type: item.Filepath
                                        ? "primary"
                                        : "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openInNewTab(item.Filepath)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "onpoint-link icon-font",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      })
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", [_vm._v("ID")]),
                    _c("vs-th", [_vm._v("Nome do arquivo")]),
                    _c("vs-th"),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pt-10" }, [_c("h4", [_vm._v("Filtrar:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }